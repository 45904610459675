import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import TextCard from '../TextCard/TextCard';

const ImageTextBoxWrapper = styled.section`
    padding: var(--sectioning-gap);
    margin: var(--page-padding);
    display: flex;
    flex-direction: column;

    .Flex-Box{
        display: flex;
        justify-content: space-between;
    }

    .Text-Card-Box{
        display: flex;
        flex-direction: column;
        gap: 6em;
    }

    .Image-Box img{
        width: 100%;
    }

    .Utility-Box{
        display: flex;
        justify-content: space-between;
        gap: calc(2 * var(--flex-gap));
    }

    @media screen and (min-width: 1440px) and (max-width: 1536px){
        .Text-Card-Box{
            gap: 4em;
        }
    }

    @media screen and (max-width: 1023px){
        .Utility-Box{
            gap: var(--flex-gap);
        }

        .Text-Card-Box{
            gap: var(--sectioning-gap);
        }
    }

    @media screen and (max-width: 768px){
        .Utility-Box{
            flex-direction: column;
        }
    }
`

const ImageTextBox = ({ src, IconI, IconII, UtilityI, UtilityII, HeadTextI, HeadTextII, BodyI, BodyII, Button, widthIB, widthTCB, gap, flexDirection, DisplayI, DisplayII, DisplayCardI, DisplayCardII, alignItems, UtilityIII, UtilityIV, IntroI, IntroII, One, Two, Three, Four, Five, Six, Seven, ParentGap, displayOne, displayTwo, displayThree, displayFour, displayFive, displaySix, displaySeven, displayEight, displayNine, displayTen, displayUl, IconIII, IntroIII, HeadTextIII, BodyIII, DisplayCardIII, DisplayIII, UtilityV }) => {

    const [isActive, setIsActive] = useState(false);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsActive(entry.isIntersecting);
            },
            { rootMargin: '0px', threshold: 0 }
        );

        const currentImgRef = imgRef.current;

        if (currentImgRef) {
            observer.observe(currentImgRef);
        }

        return () => {
            if (currentImgRef) {
                observer.unobserve(currentImgRef);
            }
        };
    }, []);

    return (
        <ImageTextBoxWrapper id='Image-Text-Box' style={{ gap: ParentGap }}>
            <div className='Flex-Box' style={{ gap: gap, flexDirection: flexDirection, alignItems: alignItems }}>
                <div className='Image-Box'
                    ref={imgRef} style={{ flex: widthIB }}>
                    <img src={src}
                        alt='relevant illustration' className={`${isActive ? 'slide-in' : ''}`}></img>
                </div>
                <div className='Text-Card-Box' style={{ flex: widthTCB }}>
                    <TextCard Icon={IconI}
                        Intro={IntroI}
                        HeadText={HeadTextI}
                        Body={BodyI}
                        Button={Button}
                        DisplayIcon={DisplayI}
                        Display={DisplayCardI}
                        Utility={UtilityI}
                        one={One}
                        two={Two}
                        three={Three}
                        four={Four}
                        five={Five}
                        six={Six}
                        seven={Seven}
                        displayOne={displayOne}
                        displayTwo={displayTwo}
                        displayThree={displayThree}
                        displayFour={displayFour}
                        displayFive={displayFive}
                        displaySix={displaySix}
                        displaySeven={displaySeven}
                        displayEight={displayEight}
                        displayNine={displayNine}
                        displayTen={displayTen}
                        displayUl={displayUl} />
                    <TextCard Icon={IconII}
                        Intro={IntroII}
                        HeadText={HeadTextII}
                        Body={BodyII}
                        DisplayIcon={DisplayII}
                        Display={DisplayCardII}
                        Utility={UtilityII} />
                    <TextCard
                        Icon={IconIII}
                        Intro={IntroIII}
                        HeadText={HeadTextIII}
                        Body={BodyIII}
                        DisplayIcon={DisplayIII}
                        Display={DisplayCardIII}
                        Utility={UtilityV} />
                </div>
            </div>
            <div className='Utility-Box'>
                {UtilityIII}
                {UtilityIV}
            </div>
        </ImageTextBoxWrapper>
    )
}

export default ImageTextBox